import React from "react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { DateTime } from "luxon";

import RichText from "../RichText/RichText";

import {
  card,
  img,
  textContent,
  dateRange,
  description,
} from "./ProjectCard.module.scss";

const RenderCardTitle = ({ cardType, title, link }) => {
  if (!link) {
    return <h3 className="main-headings">{title}</h3>;
  }
  if (cardType === "ContentfulInternalProjects") {
    return (
      <h3 className="main-headings">
        <Link to={`/project/${link}`}>{title}</Link>
      </h3>
    );
  }
  if (cardType === "ContentfulExternalProjects") {
    return (
      <h3 className="main-headings">
        <a href={link} target="_blank" rel="noopener noreferrer">
          {title}
        </a>
      </h3>
    );
  }
  return null;
};

const RenderDateRange = ({ type, start, end }) => {
  if ((!start && !end) || type !== "upcoming") {
    return null;
  }

  const startDateObj = DateTime.fromISO(start);

  if (!end) {
    return <p className={dateRange}>{startDateObj.toFormat("MMMM d, yyyy")}</p>;
  }

  const endDateObj = DateTime.fromISO(end);
  const sameYear = startDateObj.year === endDateObj.year;

  if (sameYear) {
    return (
      <p className={dateRange}>
        {startDateObj.toFormat("MMMM d")} -{" "}
        {endDateObj.toFormat("MMMM d, yyyy")}
      </p>
    );
  }
  return (
    <p className={dateRange}>
      {startDateObj.toFormat("MMMM d, yyyy")} -
      {endDateObj.toFormat("MMMM d, yyyy")}
    </p>
  );
};

const ProjectCard = ({ content, type, className }) => {
  const {
    slug,
    externalLink,
    projectName,
    previewDescription,
    previewImage,
    startDate,
    endDate,
    accreditation,
  } = content;

  return (
    <article className={`${className} ${card}`}>
      {previewImage ? (
        <GatsbyImage
          image={previewImage.gatsbyImageData}
          alt={previewImage.description}
          className={img}
        />
      ) : (
        <div className={img} />
      )}
      <div className={textContent}>
        <RenderCardTitle
          cardType={content.__typename}
          title={projectName}
          link={slug || externalLink}
        />
        <h4>{accreditation}</h4>
        <RenderDateRange type={type} start={startDate} end={endDate} />
        <RichText richText={previewDescription} className={description} />
      </div>
    </article>
  );
};

export default ProjectCard;
