import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout/Layout";
import ProjectCard from "../components/ProjectCard/ProjectCard";
import PageDetails from "../components/PageDetails/PageDetails";

import { projectsList, projectCard } from "./projects.module.scss";

const Projects = ({ data }) => {
  const { contentfulProjectsPage } = data;
  const {
    pageTitle,
    pageDescription,
    upcomingProjectsTitle,
    upcomingProjects,
    inDevelopmentSectionTitle,
    inDevelopmentProjects,
    pastProjectsSectionTitle,
    pastProjects,
  } = contentfulProjectsPage;

  return (
    <Layout>
      <PageDetails heading={pageTitle} description={pageDescription} />
      {upcomingProjects && (
        <section className={`grid-wrapper ${projectsList}`}>
          <h2>{upcomingProjectsTitle}</h2>
          {upcomingProjects.map((card) => (
            <ProjectCard
              key={card.id}
              content={card}
              className={projectCard}
              type="upcoming"
            />
          ))}
        </section>
      )}
      {inDevelopmentProjects && (
        <section className={`grid-wrapper ${projectsList}`}>
          <h2>{inDevelopmentSectionTitle}</h2>
          {inDevelopmentProjects.map((card) => (
            <ProjectCard
              key={card.id}
              content={card}
              className={projectCard}
              type="inDev"
            />
          ))}
        </section>
      )}
      {pastProjects && (
        <section className={`grid-wrapper ${projectsList}`}>
          <h2>{pastProjectsSectionTitle}</h2>
          {pastProjects.map((card) => (
            <ProjectCard
              key={card.id}
              content={card}
              className={projectCard}
              type="past"
            />
          ))}
        </section>
      )}
    </Layout>
  );
};

export default Projects;

export const projectsQuery = graphql`
  query {
    contentfulProjectsPage(id: { eq: "bec0a3e5-3066-5dd5-b47f-df5987c81d79" }) {
      pageTitle
      pageDescription {
        raw
      }
      upcomingProjectsTitle
      upcomingProjects {
        ... on ContentfulInternalProjects {
          id
          __typename
          slug
          projectName
          previewDescription {
            raw
            references {
              ... on ContentfulButton {
                contentful_id
                __typename
                text
                link
              }
            }
          }
          previewImage {
            gatsbyImageData
            description
          }
          startDate
          endDate
          accreditation
        }
        ... on ContentfulExternalProjects {
          id
          __typename
          projectName
          accreditation
          startDate
          endDate
          previewImage {
            description
            gatsbyImageData
          }
          previewDescription {
            raw
            references {
              ... on ContentfulButton {
                contentful_id
                __typename
                text
                link
              }
            }
          }
          externalLink
        }
      }
      inDevelopmentSectionTitle
      inDevelopmentProjects {
        ... on ContentfulInternalProjects {
          id
          __typename
          slug
          projectName
          previewDescription {
            raw
            references {
              ... on ContentfulButton {
                contentful_id
                __typename
                text
                link
              }
            }
          }
          previewImage {
            gatsbyImageData
            description
          }
          startDate
          endDate
          accreditation
        }
        ... on ContentfulExternalProjects {
          id
          __typename
          projectName
          accreditation
          startDate
          endDate
          previewImage {
            description
            gatsbyImageData
          }
          previewDescription {
            raw
            references {
              ... on ContentfulButton {
                contentful_id
                __typename
                text
                link
              }
            }
          }
          externalLink
        }
      }
      pastProjectsSectionTitle
      pastProjects {
        ... on ContentfulInternalProjects {
          id
          __typename
          slug
          projectName
          previewDescription {
            raw
            references {
              ... on ContentfulButton {
                contentful_id
                __typename
                text
                link
              }
            }
          }
          previewImage {
            gatsbyImageData
            description
          }
          startDate
          endDate
          accreditation
        }
        ... on ContentfulExternalProjects {
          id
          __typename
          projectName
          accreditation
          startDate
          endDate
          previewImage {
            description
            gatsbyImageData
          }
          previewDescription {
            raw
            references {
              ... on ContentfulButton {
                contentful_id
                __typename
                text
                link
              }
            }
          }
          externalLink
        }
      }
    }
  }
`;

export { Head } from "../components/Head/Head";
